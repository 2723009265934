import React from 'react'
import {Heading,Box,Text,HStack, SimpleGrid} from '@chakra-ui/react'
import {StaticImage} from 'gatsby-plugin-image'
import {motion} from 'framer-motion'
import {Link} from 'gatsby'
import MeinSeo from '../components/seo'

export default function Beitrag7(){

return(
<>
  <MeinSeo title="Bruchstücke des Roten Fensters " description='Ausstellung Bruchstücke des Roten Fensters  '/>
  

  <motion.div
  initial={{opacity:0,scale:.7,paddingBottom:"40px"}}
  animate={{opacity:1,scale:1}}
  transition={{duration:1,delay:.3}}
  >

<Box  mb="2">
{/* <Text pb="1.5" fontWeight="400"  lineHeight="1" fontSize={["3xl","4xl","4xl"]}> */}
<Heading  variant="Titel">
Bruchstücke des „Roten Fensters“
</Heading>
  <Text  pt="3" fontWeight="400" lineHeight="1.2" fontSize={["3xl","4xl","4xl"]}> 
    "Ich wünsche mir, dass meine künstlerischen Impulse zu einem Engagement für gelebte Toleranz führen." <br/>
    <span style={{color:"Text",fontSize:".9rem"}}>Der Künstler Karl Martin Hartmann</span>
 </Text>
  </Box>
  </motion.div>
  
  <HStack  mb="0" justifyContent="space-between">
<Box>
   

                   
                    
                   
<StaticImage
  src="../images/fragmente/f1.jpg"
  alt="Werner Abbildung"
  layout='constrained'

/>
</Box>
<Box>
<StaticImage
src="../images/fragmente/f2.jpg"
  alt="Werner Abbildung"
  layout='constrained'

/>

</Box>
<Box>
<StaticImage
src="../images/fragmente/f3.jpg"
  alt="Werner Abbildung"
  layout='constrained'

/>

</Box>

</HStack>

<Text variant='Bild'>Leihgaben von Fritz Stüber u. Beate Leiber, Peter Weis Christa u. Michael Dausner, Maria u. Dieter Kochskämper, Karl-Heinz Schleis u. Thea Feldhege, Randolf Kauer, Andreas Stüber</Text>

<SimpleGrid mt="1" columns={[1,2,2]} gap="5" pb="60px">
  <Box>
<Text variant='solid'>
Das „Rote Fenster“ war eine von dem Künstler Karl-Martin Hartmann (geb. 1948) geschaffene Installation, die – bis weit ins Rheintal sichtbar – von 2007 bis 2010 in der Bacharacher Wernerkapelle ausgestellt war. Thematisch und räumlich stellte sie den Bezug zwischen Wernerkult und Heinrich Heines Fragment „Der Rabbi von Bacherach“, zwischen Antijudaismus und dem Aufruf zur Toleranz sowie zwischen Bacharach und Oberwesel her. 
</Text>
</Box>
<Box>
<Text  variant='solid'>
Das Fenster existiert nicht mehr, wohl aber existieren Fragmente der ursprünglichen Installation. Da die genannten Bezüge auch für den Parcours von Belang sind, stellen wir eine Dokumentation des „Roten Fensters“ mitsamt einigen Fragmenten aus. Sechs der Fragmente, Leihgaben von Bacharacher Bürgern (s.o), werden auch in der Ausstellung zum Wernerkult (Oberwesel) zu sehen sein. 
</Text>
</Box>
</SimpleGrid>

            <Link to="/rotes-fenster"><Text color="blue.500" textAlign="center" _hover={{color:'green.300'}} variant='solid'>&rarr; Weitere Fotos zum roten Fenster <br/>&amp; ein Interview mit Peter Keber, Bauverein Wernerkapelle. Interview </Text></Link>
        </>
    )
}